.content-area {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.button-group {
  padding-top: 20px;
}

.performance-view {
  .name-input {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 20px;
  }

  .performance-view-save-button {
    margin-left: 10px;
  }

  .performance-view-button {
    margin: 0 10px;
    cursor: pointer;
    font-size: 22px;
    width: 30px;
    margin-left: auto;
    color: #333;
    &:hover {
      color: lighten(#333, 30%);
    }

    &.active {
      color: rgb(70, 121, 216);

      &:hover {
        color: lighten(rgb(70, 121, 216), 15%);
      }
    }
  }
}

.performers-button {
  button {
    margin-top: 15px;
    margin-bottom: 30px;
  }
}

.performance {
  padding-top: 10px;
  padding-bottom: 30px;
  border-top: 2px solid #aaa;
  border-bottom: 2px solid #aaa;
  button {
    margin-top: 15px;
  }
}

.score {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  .score-title {
    margin-right: auto;
    padding-right: 10px;
    
  }
}